import { jwtDecode } from "jwt-decode";
import React, { Suspense, useEffect } from "react";
import { Outlet, Route, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

const UnProtectedRoute = () => {
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : null;
  const navigate = useNavigate();
  useEffect(() => {
    if (token && decodedToken) {
      return navigate("/dashboard");
    }
  }, [token, decodedToken, navigate]);

  return (
    <>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      
    </>
  );
};


export default UnProtectedRoute