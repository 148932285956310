
import axios from "axios"


export const initializeInterceptor=()=>{
    axios.interceptors.response.use(
        (response)=>{
          return response
          
        },
        (err)=>{
          if (err.response.status===401 && err.response.data.message==="Unauthorized") {
            localStorage.clear()
           window.location.href="/"
           return;
          }
          return Promise.reject(err)
        }
      )
}

