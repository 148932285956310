import * as React from "react";

const LevelupCloseButton = ({darkMode}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      cx="12"
      cy="12"
      r="11.75"
      stroke={`${darkMode ? "#D9D9D9":"black"}`}
      strokeWidth="0.5"
    ></circle>
    <path stroke={`${darkMode ? "#D9D9D9":"black"}`} strokeWidth="0.75" d="m6 6 12 12M6 18 18 6"></path>
  </svg>
);

export default LevelupCloseButton;
