import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import React, { useState, useEffect, useContext } from "react";
import ReactGA from "react-ga4";
import "./App.css";
import axios from "axios";
import { registerServiceWorker } from "./utils/serviceWorkerUtils";
import ProtectedRoute from "./components/AuthWrappers/ProtectedRoute";
import UnProtectedRoute from "./components/AuthWrappers/UnprotectedRoute";
import { MathJaxContext } from "better-react-mathjax";
import {
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogTitle,
  ThemeProvider,
} from "@mui/material";
import isDesktop from "./isDesktop";
import LearnDynamicMobile from "./pages/LearnPage/DynamicPages/LearnMobileDynamic";
import LearnDetailsDynamicMobile from "./pages/LearnPage/DynamicPages/LearnDetailsDynamicMob";
import LabsMobileDynamic from "./pages/LabsPage/LabsPageDynamic/LabsMobileDynamic";
import LabDetailsDynamicMobile from "./pages/LabsPage/LabsPageDynamic/LabDetailsDynamicMobile";
import LevelDownAudio from "./assets/Sounds/level_down.mp3";
import { DarkModeContextProvider, DarkModeContext } from "./context/DarkMode";
import { initializeInterceptor } from "./utils/initialise";
const publicVapidKey = process.env.REACT_APP_PUBLIC_VAPID_KEY;
axios.defaults.withCredentials = true;

const isDesktopDevice = isDesktop();
// console.log(publicVapidKey);
const level = localStorage.getItem("level");
const levelColors = {
  2: "--primary-color-lvl-2", // Level 2 color = GREEN
  3: "--primary-color-lvl-3", // Level 3 color = YELLOW
  4: "--primary-color-lvl-4", // Level 4 color = RED
  5: "--primary-color-lvl-5", // Level 5 color = #FF9900
};
const changeColorDark = levelColors[level] || "--primary-color-lvl-1";
const levelColorsLight = {
  2: "--lgt-primary-color-lvl-2", // Level 2 color = GREEN
  3: "--lgt-primary-color-lvl-3", // Level 3 color = YELLOW
  4: "--lgt-primary-color-lvl-4", // Level 4 color = RED
  5: "--lgt-primary-color-lvl-5", // Level 5 color = #FF9900
};
const changeColorLight = levelColorsLight[level] || "--lgt-primary-color-lvl-1";
const getCSSVariable = (variable) =>
  getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: getCSSVariable(changeColorDark),
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: getCSSVariable(changeColorLight),
    },
  },
});

//All Components
const BlogsDetails = React.lazy(() => import("./pages/Blogs/BlogsDetails"));
const LabPage = React.lazy(() => import("./pages/LabsPage/LabsPage"));
const LabDetails = React.lazy(() => import("./pages/LabsPage/LabDetails"));
const LearnMobile = React.lazy(() => import("./pages/LearnPage/LearnMobile"));
const BlogsMobile = React.lazy(() => import("./pages/Blogs/BlogsMobile"));
const RewardsMobile = React.lazy(() => import("./pages/Rewards/Rewards"));
const AboutUs = React.lazy(() => import("./pages/AboutUs/AboutUs"));
const BlogCategory = React.lazy(() => import("./pages/Blogs/BlogCategory"));
const LearnDetailsMobile = React.lazy(() =>
  import("./pages/LearnPage/LearnDetailsMobile")
);
const LoginForm = React.lazy(() => import("./components/Auth/Login/LoginForm"));
const AchievementDetails = React.lazy(() =>
  import("./pages/AchievementDetails/AchievementDetailsMobile")
);
const AchievementMobile = React.lazy(() =>
  import("./components/Achievement/AchievementMobile")
);
const SignupForm = React.lazy(() =>
  import("./components/Auth/Signup/SignupForm")
);
const LandingPage = React.lazy(() =>
  import("./components/LandingPage/LandingPage")
);
const Skills = React.lazy(() => import("./components/Skills/Skills"));
const SkillDetails = React.lazy(() =>
  import("./components/SkillDetails/SkillDetails")
);
const Wrapper = React.lazy(() => import("./components/Wrapper/Wrapper"));
const Profile = React.lazy(() => import("./pages/Profile/Profile"));
const EditProfileForm = React.lazy(() =>
  import("./components/EditProfileForm/EditProfileForm")
);
const Mission = React.lazy(() => import("./components/Mission/Mission"));
const ExperimentComponent = React.lazy(() =>
  import("./components/ExperimentComponent/ExperimentComponent")
);
const Labs = React.lazy(() => import("./components/Labs/Labs"));
const War = React.lazy(() => import("./components/War/War"));
const Battle = React.lazy(() => import("./components/Battle/Battle"));
const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const ThankYou = React.lazy(() => import("./pages/ThankYou/ThankYou"));
const TransactionComponent = React.lazy(() =>
  import("./components/Transaction/TransactionComponent")
);
const FollowFollowing = React.lazy(() =>
  import("./components/FollowFollowing/FollowFollowing")
);
const HelpPageComponent = React.lazy(() =>
  import("./components/HelpPageComponent/HelpPageComponent")
);
const Report = React.lazy(() => import("./components/Report/Report"));
const ForgotPasswordComponent = React.lazy(() =>
  import("./pages/Passwords/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./components/ResetPassword/ResetPassword")
);
const Challenges = React.lazy(() =>
  import("./components/Challenges/Challenges")
);
const OrderComponent = React.lazy(() =>
  import("./components/OrderComponent/OrderComponent")
);
const OrderDetails = React.lazy(() =>
  import("./components/OrderComponent/OrderDetails")
);
const CartComponent = React.lazy(() =>
  import("./components/CartPageComponent/CartComponent")
);
const SummerCamp = React.lazy(() => import("./pages/SummerCamp/SummerCamp"));
const RegisterCamp = React.lazy(() =>
  import("./pages/SummerCamp/RegisterCamp")
);
const QRCode = React.lazy(() => import("./pages/QRCode/QRCode"));
const GraphMobile = React.lazy(() => import("./pages/GraphPage/GraphMobile"));
const LevelUpMobile = React.lazy(() =>
  import("./components/LevelUp/LevelUpMobile")
);
const Specialization = React.lazy(() =>
  import("./pages/Specialization/Specialization")
);

const HowItWorksMobile = React.lazy(() =>
  import("./pages/HowItWorks/HowItWorksMobile")
);
const OurStoryMobile = React.lazy(() =>
  import("./pages/OurStory/OurStoryMobile")
);
const PrivaryPolicy = React.lazy(() =>
  import("./pages/PrivacyPolicy/PrivacyPolicy")
);
const TermsAndConditions = React.lazy(() =>
  import("./pages/PrivacyPolicy/TermsAndConditions")
);
const AutomationExcercise = React.lazy(() =>
  import("./pages/Automation/AutomationExcercise")
);

// Configuration for MathJax
const config = {
  loader: { load: ["input/asciimath"] },
  options: {
    // enableMenu: true,
    menuOptions: {
      settings: {
        inTabOrder: false,
      },
    },
  },
};

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

function App() {
  const [testRender, setTestRender] = useState(null);
  const [isStreakonBreak, setIsStreakOnBreak] = useState(false);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const { darkMode } = useContext(DarkModeContext);
  console.log(testRender && process.env.REACT_APP_BUILD_ENV === "LOCAL");
  initializeInterceptor();
// Function to dynamically import the test component
  const importTestComponent = () => {
    if (process.env.REACT_APP_BUILD_ENV === "LOCAL") {
      import("./testComponents/Crontester")
        .then((module) => {
          const Crontester = module.default;
          setTestRender(<Crontester />);
        })
        .catch((error) => {
          console.error("Failed to load Crontester module", error);
          // Handle error if necessary
        });
    }
  };

// Function to subscribe to push notifications
  const subscribeToNotification = async () => {
    console.log("Inside subscribe to notification");

    try {
      // const registration = ServiceWorkerContainer.controller;
      console.log("saddasdsd");
      if ("serviceWorker" in navigator) {
        const registration = await navigator.serviceWorker.ready;
        console.log(registration, "fhsaofhosahfioaf");

        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: publicVapidKey,
        });
        console.log(subscription);
      } else {
        console.log("Browser doesn't support serviceworker");
      }
    } catch (error) {
      console.log(error);
    }
  };

 // Function to fetch streak data for break
  const fetchStreaksforBreak = async () => {
    try {
      // console.log(userId);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/freezeStreak/streakforbreak/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "Hello World");
      if (response.status === 200) {
        const data = response?.data?.data;
        console.log(data, "Streak data");

        setIsStreakOnBreak(data?.break_dialog); // Update state with break dialog status
      }
    } catch (error) {
      console.error(error.response.data.message);
    }
  };
  console.log(isStreakonBreak, "Is Streak on break");

// Function to close the streak break dialog
  const closeBreakDialog = async () => {
    try {
      // console.log(userId);
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/freezeStreak/closeBreakDialog/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setIsStreakOnBreak(false);
      }
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

// useEffect to run on component mount
  useEffect(() => {
    importTestComponent();
    registerServiceWorker(
      () => {},
      () => {},
      publicVapidKey
    );
    // subscribeToNotification()
  }, []);

// useEffect to fetch streak data when userId changes
  useEffect(() => {
    if (userId) {
      fetchStreaksforBreak();
    }
  }, [userId]);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <MathJaxContext config={config}>
        <Router>
          <Routes>
            <Route element={<UnProtectedRoute />}>
              <Route path={"/"} element={<LandingPage />} />
              <Route path={"/login"} element={<LoginForm />} />
              <Route path={"/signup"} element={<SignupForm />} />
              <Route
                path={"/forgot-password"}
                element={<ForgotPasswordComponent />}
              />
              <Route path={"/signup/:refCode"} element={<SignupForm />} />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path={"/skills"} element={<Skills />} />
              <Route
                path={"/skills/:skillId/stages"}
                element={<SkillDetails />}
              />
              <Route
                path={"/skills/:skillId/specialization/:specId/stages"}
                element={<SkillDetails />}
              />
              <Route
                path={"/skills/:skillId/stages/:stageId/mission/:missionId"}
                element={<Mission />}
              />
              <Route
                path={
                  "/skills/:skillId/specialization/:specId/stages/:stageId/mission/:missionId"
                }
                element={<Mission />}
              />
              <Route path={"/profile/:userId"} element={<Profile />} />
              <Route path={"/edit-profile"} element={<EditProfileForm />} />
              <Route path={"/labs"} element={<Labs />} />
              <Route
                path={"/labs/:labId/experiments"}
                element={<ExperimentComponent />}
              />
              <Route path={"/skills/:skillId/war/:warId"} element={<War />} />
              <Route
                path={"/skills/:skillId/specialization/:specId/war/:warId"}
                element={<War />}
              />
              <Route
                path={"/skills/:skillId/stages/:stageId/battle/:battleId"}
                element={<Battle />}
              />
              <Route
                path={
                  "/skills/:skillId/specialization/:specId/stages/:stageId/battle/:battleId"
                }
                element={<Battle />}
              />
              <Route path={"/dashboard"} element={<Dashboard />} />
              <Route path={"/thankyou"} element={<ThankYou />} />
              <Route
                path={"/transactions"}
                element={<TransactionComponent />}
              />
              <Route path={"/follow/:userId"} element={<FollowFollowing />} />
              <Route
                path={"/labs/:labId/experiments/report/:attemptId"}
                element={<Report />}
              />
             
              <Route path={"/reset-password"} element={<ResetPassword />} />
              <Route path={"/challenges"} element={<Challenges />} />
              {/* <Route path={"/notifications"} element={NotificationPage} /> */}
              <Route path={"/rewards"} element={<RewardsMobile />} />
              <Route path={"/orders"} element={<OrderComponent />} />
              <Route
                path={"/order-details/:transactionId"}
                element={<OrderDetails />}
              />
              <Route path={"/cart"} element={<CartComponent />} />
              <Route path={"/summercamp2024"} element={<SummerCamp />} />
              <Route path={"/register"} element={<RegisterCamp />} />
              <Route path={"/qr-code/*"} element={<QRCode />} />
              <Route path={"/graph/:userId"} element={<GraphMobile />} />
              <Route
                path={"/specialization/:specId/*"}
                element={<Specialization />}
              />
              <Route
                path={"/achievement-details/:userId"}
                element={<AchievementDetails />}
              />
              <Route
                path={"/automation/excercise/:numOfQuestion"}
                element={<AutomationExcercise />}
              />
            </Route>
            <Route element={<UnProtectedRoute />}>
              <Route path={"/how-it-works"} element={<HowItWorksMobile />} />
              <Route
                path={"/blogs/category/:catId"}
                element={<BlogCategory />}
              />
              <Route
                path={"/blogs/details/:blogId"}
                element={<BlogsDetails />}
              />
              <Route path={"/blogs"} element={<BlogsMobile />} />
              <Route path={"/learn-dynamic"} element={<LearnMobile />} />
              <Route
                path={"/learn-dynamic/:title"}
                element={<LearnDetailsMobile />}
              />
              <Route path={"/learn"} element={<LearnDynamicMobile />} />
              <Route
                path={"/learn/:title"}
                element={<LearnDetailsDynamicMobile />}
              />
              <Route path={"/lab-dynamic"} element={<LabPage />} />
              <Route path={"/lab-dynamic/:title"} element={<LabDetails />} />
              <Route path={"/lab-page"} element={<LabsMobileDynamic />} />
              <Route
                path={"/lab-page/:title"}
                element={<LabDetailsDynamicMobile />}
              />
              <Route path={"/ourStory"} element={<OurStoryMobile />} />
              <Route path={"/help"} element={<HelpPageComponent />} />
              {testRender && process.env.REACT_APP_BUILD_ENV === "LOCAL" && (
                <Route path="/test" element={testRender} />
              )}
            </Route>
            <Route path={"/aboutus"} element={<AboutUs />} />
            <Route path={"/privacy"} element={<PrivaryPolicy />} />
            <Route path={"/terms"} element={<TermsAndConditions />} />
          </Routes>
          {/* <AnimatedRoutes /> */}
        </Router>
        <Dialog
          open={isStreakonBreak}
          onClose={closeBreakDialog}
          sx={{
            "& .MuiPaper-root": {
              background: "#000",
              border: "1px solid #333333",
              width: isDesktopDevice ? "25.154vw" : "92.233vw",
              textAlign: "center",
            },
          }}
        >
          <DialogTitle
            sx={{
              fontSize: isDesktopDevice
                ? "1vw !important"
                : "3.398vw !important",
              color: "white",
            }}
          >
            {" "}
            A Streak Break has been used
          </DialogTitle>
          <DialogActions
            sx={{
              justifyContent: "center",
            }}
          >
            <Button
              onClick={closeBreakDialog}
              sx={{
                fontSize: isDesktopDevice
                  ? "1vw !important"
                  : "3.398vw !important",
              }}
              style={{
                color: "black",
                backgroundColor: darkMode ? changeColorDark : changeColorLight,
                textTransform: "capitalize",
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </MathJaxContext>
    </ThemeProvider>
  );
}

export default App;
