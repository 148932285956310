import React, { lazy, useContext } from "react";
import Level1UPSvg from "./Level1UPSvg";
import Level2UPSvg from "./Level2UPSvg";
import Level3UPSvg from "./Level3UPSvg";
import Level4UPSvg from "./Level4UPSvg";
import Level5UPSvg from "./Level5UPSvg";
import isDesktop from "../../isDesktop";
import { motion, useAnimation } from "framer-motion";
import { useState, useRef, useEffect } from "react";
import LevelupCloseButton from "../../assets/LevelupCloseButton";
import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
} from "@mui/material";
import styles from "./LevelUpMobile.module.css";
import { DarkModeContext } from "../../context/DarkMode";
import LevelUpAudio from '../../assets/Sounds/level_up.mp3';
const DesktopComponent = lazy(() => import("./LevelupPage"));


const LevelUpMobile = ({
  currLevel = 5,
  prevLevel = 999,
  name = "Aniket",
  setShowDialog = () => {},
}) => {
  const LevelUpAudioObj = new Audio(LevelUpAudio);
  const [open, setOpen] = useState(false);
  const dialogRef = useRef(null);
  const [parentAnimationCompleted, setParentAnimatiocompleted] =
    useState(false);
  const hasLeveledup = Number(currLevel) - Number(prevLevel);
  const [subHeading, setSubHeading] = useState("");
  const [levelUPSVG, setLevelUPSVG] = useState(null);
  const [text, setText] = useState("");
  const [childAnimationCompleted, setChildAnimationCompleted] = useState(false);
  const {darkMode}=useContext(DarkModeContext)
  const levels = {
    1: {
      multiplier: 1,
      minimum_sprint: 5,
      color: darkMode?"var(--primary-color-lvl-1)":"var(--lgt-primary-color-lvl-1)",
    },
    2: {
      multiplier: 1.5,
      minimum_sprint: 7,
      color: darkMode?"var(--primary-color-lvl-2)":"var(--lgt-primary-color-lvl-2)",
    },
    3: {
      multiplier: 2,
      minimum_sprint: 12,
      color: darkMode?"var(--primary-color-lvl-3)":"var(--lgt-primary-color-lvl-3)",
    },
    4: {
      multiplier: 2.5,
      minimum_sprint: 20,
      color: darkMode?"var(--primary-color-lvl-4)":"var(--lgt-primary-color-lvl-4)",
    },
    5: {
      multiplier: 3,
      minimum_sprint: 30,
      color: darkMode?"var(--primary-color-lvl-5)":"var(--lgt-primary-color-lvl-5)",
    },
  };

  const animateP1 = useAnimation();
  const animateP2 = useAnimation();
  // const animateP3 = useAnimation();
  // const animateP4 = useAnimation();

  let congratsText = "";
  let subText = "";
  // console.log(currLevel,prevLevel);

  const animate1 = useAnimation();
  const animate2 = useAnimation();
  const animate3 = useAnimation();
  const animate4 = useAnimation();
  const animate5 = useAnimation();
  const animate6 = useAnimation();

  const selectSVG = () => {
    switch (currLevel) {
      case 1:
        return (
          <Level1UPSvg
            animate1={animate1}
            animate2={animate2}
            animate3={animate3}
            animate4={animate4}
            animate5={animate5}
            animate6={animate6}
            sparkeAnimation={sparkeAnimation}
          />
        );
      case 2:
        return (
          <Level2UPSvg
            animate1={animate1}
            animate2={animate2}
            animate3={animate3}
            animate4={animate4}
            animate5={animate5}
            animate6={animate6}
            sparkeAnimation={sparkeAnimation}
          />
        );
      case 3:
        return (
          <Level3UPSvg
            animate1={animate1}
            animate2={animate2}
            animate3={animate3}
            animate4={animate4}
            animate5={animate5}
            animate6={animate6}
            sparkeAnimation={sparkeAnimation}
          />
        );
      case 4:
        return (
          <Level4UPSvg
            animate1={animate1}
            animate2={animate2}
            animate3={animate3}
            animate4={animate4}
            animate5={animate5}
            animate6={animate6}
            sparkeAnimation={sparkeAnimation}
          />
        );
      case 5:
        return (
          <Level5UPSvg
            animate1={animate1}
            animate2={animate2}
            animate3={animate3}
            animate4={animate4}
            animate5={animate5}
            animate6={animate6}
            sparkeAnimation={sparkeAnimation}
          />
        );
      default:
        return (
          <Level1UPSvg
            animate1={animate1}
            animate2={animate2}
            animate3={animate3}
            animate4={animate4}
            animate5={animate5}
            animate6={animate6}
            sparkeAnimation={sparkeAnimation}
          />
        );
        break;
    }
  };

  const startAnimation1 = async () => {
    try {
      await animate1.start({
        opacity: [0, 0.5, 0.8, 1],
        transition: {
          duration: 1,
          ease: "easeInOut",
        },
      });
      await animate2.start({
        opacity: [0, 1],
        y: [-200, 0],
        transition: {
          duration: 0.1,
          // ease:"easeInOut"
        },
      });
      await animate3.start({
        opacity: [0, 1],
        // scale:[0,1],
        transition: {
          duration: 0.5,
          ease: "linear",
        },
      });
      // await sparkeAnimation();
    } catch (error) {
      console.log(error);
    }
  };

  const sparkeAnimation = async () => {
    animate5.start({
      x: [130, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.1,
        // repeat:Infinity,
        ease: "linear",
      },
    });
    animate6.start({
      x: [-130, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.1,
        // repeat:Infinity,
        ease: "linear",
      },
    });
    animateP1.start({
      opacity: [0, 1],
      transition: {
        duration: 0.2,
        ease: "linear",
      },
    });
    animate4.start({
      opacity: [0, 1],
      transition: {
        duation: 0.1,
        ease: "linear",
      },
    });
  };

  const parentAnimation = async () => {
    try {
      await animateP2.start({
        scale: [1, 2, 1.5],
        transition: {
          duration: 0.5,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LevelUpAudioObj.play();
    setLevelUPSVG(selectSVG());
    if (hasLeveledup >= 1) {
      congratsText = `Well Done ${name}`;
      subText = "YOU HAVE UPGRADED";
      setText(congratsText);
      setSubHeading(subText);
    }
    if (hasLeveledup < 1) {
      congratsText = `Keep Going ${name}`;
      subText = "YOU HAVE DOWNGRADED";
      setText(congratsText);
      setSubHeading(subText);
    }
  }, [currLevel, prevLevel]);

  useEffect(() => {
    if (Level1UPSvg) {
      startAnimation1();
    }

    return () => {
      animate1.stop();
      animate2.stop();
      animate3.stop();
      animate4.stop();
      animate5.stop();
      animate6.stop();
    };
    }, [levelUPSVG]);
    
  useEffect(() => {
    if (childAnimationCompleted) {
      parentAnimation();
    }

    return()=>{
      animateP1.stop()
      animateP2.stop()
    }
  }, [childAnimationCompleted]);

  const isDesktopDevice = isDesktop();
  return (
    <div className={styles.mainContainer}>
      {isDesktopDevice ? (
        <DesktopComponent
        currLevel = {currLevel}
        prevLevel = {prevLevel}
        name = {name}
        setShowDialog={setShowDialog}
        />
      ) : (
        <>
          {/* <div className={styles.openButton}>
            <button
              onClick={() => {
                dialogRef.current.showModal();
              }}
            >
              Click to Open
            </button>
          </div> */}
          {/* <dialog ref={dialogRef}> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              // margin: "2rem",
            }}
          >
            <div className={styles.closeButton} onClick={() => {
                  // dialogRef.current.close();
                  setShowDialog(false);
                }}>
              <LevelupCloseButton
                darkMode={darkMode}
              />
            </div>
            <div style={{ width: "90%", height: "100%", display: "flex" }}>
              {levelUPSVG}
            </div>
            <motion.div
              className={styles.textContainer}
              style={{ paddingTop: "0", color: levels[currLevel].color }}
              initial={{ opacity: 0 }}
              animate={animateP1}
              onAnimationComplete={() => {
                setChildAnimationCompleted(true);
              }}
            >
              <motion.div className={styles.headingContainer}>
                <motion.h1
                  animate={animateP2}
                  style={{ marginTop: "0" }}
                  initial={{ scale: 1 }}
                >
                  {" "}
                  Level {currLevel}
                </motion.h1>
              </motion.div>
              <div className={styles.dynamicContentContainer}>
                <motion.div className={styles.subContainer} style={{color: !darkMode && 'black'}}>{text}</motion.div>
                <motion.div
                  style={{ color: levels[currLevel].color }}
                  className={styles.subheadingContainer}
                >
                  {subHeading}
                </motion.div>
              </div>
              <div className={styles.contentContainer}>
                <ul className={styles.contentList}>
                  <motion.li className={styles.contentListElement} key={1}>
                    Questions will be rewared with{" "}
                    {levels[currLevel].multiplier}x of usual coins
                  </motion.li>
                  <motion.li className={styles.contentListElement} key={2}>
                    Minimum no. of sprint per day will be{" "}
                    {levels[currLevel].minimum_sprint}
                  </motion.li>
                </ul>
              </div>
            </motion.div>
          </div>
          {/* </dialog> */}
        </>
      )}
    </div>
  );

  // return (
  //   <>
  //     {isDesktopDevice ? (
  //       <DesktopComponent
  //         congratsText={congratsText}
  //         levelUPSVG={levelUPSVG}
  //         currLevel={currLevel}
  //         prevLevel={prevLevel}
  //         setShowDialog={setShowDialog}
  //       />
  //     ) : (
  //       <Dialog
  //         open={true}
  //         onClose={() => setShowDialog(false)}
  //         maxWidth="md"
  //         fullWidth
  //       >
  //         <DialogTitle>
  //           <Box display="flex" justifyContent="space-between" alignItems="center">
  //             <Typography variant="h6">Level {currLevel}</Typography>
  //             <IconButton onClick={() => setShowDialog(false)}>
  //               <LevelupCloseButton />
  //             </IconButton>
  //           </Box>
  //         </DialogTitle>
  //         <DialogContent dividers>
  //           <Box
  //             display="flex"
  //             flexDirection="column"
  //             alignItems="center"
  //             justifyContent="center"
  //             width="100%"
  //           >
  //             <Box className={styles.closeButton}>
  //               <LevelupCloseButton onClick={() => setShowDialog(false)} />
  //             </Box>
  //             <Box width="90%" height="100%" display="flex" justifyContent="center">
  //               {levelUPSVG}
  //             </Box>
  //             <Box className={styles.textContainer} pt={0}>
  //               <Box className={styles.headingContainer}>
  //                 <Typography variant="h5" mt={0}>
  //                   Level {currLevel}
  //                 </Typography>
  //               </Box>
  //               <Box className={styles.subContainer}>
  //                 <Typography>{congratsText}</Typography>
  //               </Box>
  //               <Box className={styles.contentContainer}>
  //                 <ul className={styles.contentList}>
  //                   <li className={styles.contentListElement}>
  //                     Questions will be rewarded with {levels[currLevel].multiplier}x of usual coins
  //                   </li>
  //                   <li className={styles.contentListElement}>
  //                     Minimum no. of sprints per day will be {levels[currLevel].minimum_sprint}
  //                   </li>
  //                 </ul>
  //               </Box>
  //             </Box>
  //           </Box>
  //         </DialogContent>
  //       </Dialog>
  //     )}
  //   </>
  // );
};

export default LevelUpMobile;
