import styles from "./LearnDetailsDynamicMob.module.css";
import { ChevronLeft, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import axios from "axios";
import Wrapper from "../../../components/Wrapper/Wrapper";
import UnauthNav from "../../../components/Navbar/UnauthNav";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import FooterMobile from "../../../components/Footer/FooterMobile";
import isDesktop from "../../../isDesktop";
import { DarkModeContext } from "../../../context/DarkMode";

const DesktopComponent = lazy(() => import("./LearnDetailsDynamic"));

const uRItoTitle = (uri) => {
    return uri.replaceAll("-", " ");
};

const LearnDetailsDynamicMobile = () => {
    const navigate = useNavigate();
    const { title } = useParams();
    const decodedTitle = decodeURIComponent(uRItoTitle(title));
    const [content, setContent] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isValidToken, setIsValidToken] = useState(false);
    const token = localStorage.getItem("token");
    const [open, setOpen] = React.useState(false);
    const {darkMode}=useContext(DarkModeContext)
    const level=localStorage.getItem("level")
    const levelColors = {
        2: darkMode?"var(--primary-color-lvl-2)":"var(--lgt-primary-color-lvl-2)", // Level 2 color = GREEN
        3: darkMode?"var(--primary-color-lvl-3)":"var(--lgt-primary-color-lvl-3)", // Level 3 color = YELLOW
        4: darkMode?"var(--primary-color-lvl-4)":"var(--lgt-primary-color-lvl-4)", // Level 4 color = RED
        5: darkMode?"var(--primary-color-lvl-5)":"var(--lgt-primary-color-lvl-5)", // Level 5 color = #FF9900
      };
      const changeColor = levelColors[level] || (darkMode?"var(--primary-color-lvl-1)":"var(--lgt-primary-color-lvl-1)");
    const isDesktopDevice = isDesktop();

    useEffect(() => {
        checkTokenValidity();
        fetchLearnContent();
    }, [token, decodedTitle]);

    const checkTokenValidity = () => {
        if (token) {
            try {
                jwtDecode(token);
                setIsValidToken(true);
            } catch (error) {
                console.error("Invalid token:", error.message);
            }
        }
    };

// Fetch learn content from API based on the title
    const fetchLearnContent = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/learn-seo/learn/title/${decodedTitle}`);
            console.log("API Response:", response);
            setContent(response.data.data || {});
        } catch (error) {
            console.error("Error fetching learn content:", error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLinkClick = () => {
        navigate("/learn");
    };

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Suspense fallback={<div>Loading...</div>}>
            {isDesktopDevice ? (
                <DesktopComponent />
            ) : (
                <>
                    {isValidToken ? <Wrapper /> : <UnauthNav />}
                    <div style={{ marginLeft: isValidToken ? "4vw" : 0 }}>
                        <div className={styles.pageWrapper}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    margin: isValidToken ? '3.5rem 1.5rem 2rem 1.5rem' : '1.5rem 1.5rem 2rem 1.5rem'
                                }}
                            >
                                <ChevronLeft onClick={handleLinkClick} />
                                <div className={styles.pageTitle}>{content?.title}</div>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1.25rem' }}>
                                <img src={content.image_url} alt={content.title} className={styles.image}
                                    style={{
                                        width: '23.25rem',
                                        height: '15rem',
                                        borderRadius: '4px'
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <List
                                    sx={{
                                        width: '90%',
                                        backgroundColor: 'transparent',
                                        border: `2px solid ${changeColor}`,
                                        borderRadius: '4px',
                                        padding: '0',
                                    }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                >
                                    <ListItemButton
                                        onClick={handleClick}
                                        style={{
                                            fontSize: '0.75rem',
                                            padding: '0 0.5rem 0 0.5rem',
                                        }}
                                    >
                                        <ListItemText primary="Table of Contents" />
                                        {open ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={open} timeout="auto" unmountOnExit sx={{ zIndex: '1', top: '100%' }}>
                                        <List component="div" disablePadding sx={{ width: '100%' }}>
                                            {content.subtitle_titles && JSON.parse(content.subtitle_titles).map((subtitle, subIndex) => (
                                                <ListItemButton
                                                    sx={{ backgroundColor: darkMode ? "#000" : "var(--bg-color-9)", borderBottom: darkMode ? '2px solid #333333':'2px solid var(--lgt-tertiary-bg-color)' }}
                                                    key={subIndex}
                                                >
                                                    <ListItemText onClick={handleClick} primary={subtitle} />
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                </List>
                            </div>
                            <div style={{ marginTop: "2.051vw" }}>
                                <div className={styles.paraDiv}>
                                    {content.subtitle_titles &&
                                        content.subtitle_descriptions &&
                                        JSON.parse(content.subtitle_titles).map((subtitle, index) => (
                                            <div key={index}>
                                                <div className={styles.subTitle} style={{ color: changeColor}}>{subtitle}</div>
                                                <p className={styles.paraStyle} dangerouslySetInnerHTML={{ __html: JSON.parse(content.subtitle_descriptions)[index] }}></p>
                                                {!token && index === 0 && (
                                                    <div className={`${!darkMode && 'signup-band-light'}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '0.75rem', margin: '1.5rem 0' }}>
                                                        <h4 style={{ fontSize: '1rem', margin: '0' }}>
                                                            Get A Free Trial - Sign Up Now!
                                                        </h4>
                                                        <p style={{ margin: '0', fontSize: '0.625rem' }}>
                                                            "Your free trial is one step away - sign up and begin today!"
                                                        </p>
                                                        <Link to={"/signup"} variant="body2">
                                                            <button
                                                                initial={{ y: 50 }}
                                                                whileInView={{ y: 0 }}
                                                                viewport={{ once: true }}
                                                                transition={{
                                                                    duration: 0.5,
                                                                    delay: 0.5,
                                                                }}
                                                                className={styles.consultButton}
                                                                style={{ background: changeColor }}
                                                            >
                                                                Sign Up &#8594;
                                                            </button>
                                                        </Link>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '0.75rem', margin: '1.5rem 0', marginBottom: '3rem' }}>
                                <h4 style={{ fontSize: '1rem', margin: '0' }}>
                                    Get Free Consultation Now!
                                </h4>
                                <p style={{ margin: '0', fontSize: '0.625rem' }}>
                                    "Quick call, instant answers: Tackle all your math and education
                                    queries!"
                                </p>
                                <Link to={"/help"} variant="body2">
                                    <button
                                        initial={{ y: 50 }}
                                        whileInView={{ y: 0 }}
                                        viewport={{ once: true }}
                                        transition={{
                                            duration: 0.5,
                                            delay: 0.5,
                                        }}
                                        style={{background: changeColor}}
                                        className={styles.consultButton}
                                    >
                                        Consult Now &#8594;
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {!isValidToken && <FooterMobile />}
                </>
            )}
        </Suspense>
    );
};

export default LearnDetailsDynamicMobile;